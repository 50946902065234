@import "reboot";
@import "~font-awesome/scss/font-awesome";
@import "utility";
@import "discord";
@import "purchase-history";

/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
    font-family: OpenSans-Regular;
    src: url("../assets/fonts/OpenSans/OpenSans-Regular.ttf");
}

@font-face {
    font-family: OpenSans-Bold;
    src: url("../assets/fonts/OpenSans/OpenSans-Bold.ttf");
}

/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    font-family: OpenSans-Regular, sans-serif;
    color: #fff;
    background: #1a1a1a;
}

/*---------------------------------------------*/
a {
    font-family: OpenSans-Regular;
    color: #ffffff;
    text-decoration: none;
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

a:focus {
    outline: none !important;
}

a:hover {
    text-decoration: none;
    color: #fff;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #ffffff;
    margin: 0px;
}

p {
    font-family: OpenSans-Regular;
    font-size: 14px;
    line-height: 1.7;
    margin: 0px;
}

ul,
li {
    margin: 0px;
    list-style-type: none;
}

/*---------------------------------------------*/
input {
    outline: none;
    border: none;
}

textarea {
    outline: none;
    border: none;
}

textarea:focus,
input:focus {
    border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}
input:focus:-moz-placeholder {
    color: transparent;
}
input:focus::-moz-placeholder {
    color: transparent;
}
input:focus:-ms-input-placeholder {
    color: transparent;
}

textarea:focus::-webkit-input-placeholder {
    color: transparent;
}
textarea:focus:-moz-placeholder {
    color: transparent;
}
textarea:focus::-moz-placeholder {
    color: transparent;
}
textarea:focus:-ms-input-placeholder {
    color: transparent;
}

input::-webkit-input-placeholder {
    color: #ffffff;
}
input:-moz-placeholder {
    color: #ffffff;
}
input::-moz-placeholder {
    color: #ffffff;
}
input:-ms-input-placeholder {
    color: #ffffff;
}

textarea::-webkit-input-placeholder {
    color: #ffffff;
}
textarea:-moz-placeholder {
    color: #ffffff;
}
textarea::-moz-placeholder {
    color: #ffffff;
}
textarea:-ms-input-placeholder {
    color: #ffffff;
}

label {
    display: block;
    margin: 0;
}

/*---------------------------------------------*/
button {
    outline: none !important;
    border: none;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

iframe {
    border: none !important;
}

.limiter {
    width: 100%;
    margin: 0 auto;
    max-width: 1260px;
}

body {
    overflow: hidden;
}

.hvr-grow {
    display: inline-block;
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
    transform: scale(1.1);
}

/* Buzz Out */
@-webkit-keyframes hvr-buzz-out {
    10% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }
    20% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }
    30% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }
    40% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }
    50% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg);
    }
    60% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg);
    }
    70% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg);
    }
    80% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg);
    }
    90% {
        -webkit-transform: translateX(1px) rotate(0);
        transform: translateX(1px) rotate(0);
    }
    100% {
        -webkit-transform: translateX(-1px) rotate(0);
        transform: translateX(-1px) rotate(0);
    }
}
@keyframes hvr-buzz-out {
    10% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }
    20% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }
    30% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }
    40% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }
    50% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg);
    }
    60% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg);
    }
    70% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg);
    }
    80% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg);
    }
    90% {
        -webkit-transform: translateX(1px) rotate(0);
        transform: translateX(1px) rotate(0);
    }
    100% {
        -webkit-transform: translateX(-1px) rotate(0);
        transform: translateX(-1px) rotate(0);
    }
}
.hvr-buzz-out {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-buzz-out:hover,
.hvr-buzz-out:focus,
.hvr-buzz-out:active {
    -webkit-animation-name: hvr-buzz-out;
    animation-name: hvr-buzz-out;
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

.container {
    width: 100%;
    min-height: 100vh;
    padding: 1rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.flex-grow {
    flex-grow: 1;
}

.logo-image {
    width: 5rem;
}

.title {
    width: 100%;
    display: block;
    font-family: OpenSans-Regular;
    font-size: 30px;
    color: #ffffff;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
}

.title2 {
    width: 100%;
    display: block;
    font-family: OpenSans-Regular;
    font-size: 25px;
    color: #ffffff;
    line-height: 1.2;
    text-align: center;
}

.title3 {
    width: 100%;
    display: block;
    font-family: OpenSans-Regular;
    font-size: 22px;
    color: #ffffff;
    line-height: 1.2;
    text-align: center;
}

.logo {
    width: 200px;
    height: 200px;
    background-position: center;
    background-size: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.profile-wrapper {
    display: flex;
    flex-direction: column;
    color: #ffffff;
    margin-bottom: 2rem;
}

.profile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .profile-persona {
        display: flex;
        align-items: center;
        margin-right: 2rem;

        .profile-image {
            border-radius: 0.5rem;
            width: 2.5rem;
            margin-right: 0.5rem;
        }
    }
}

.discord-linking-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .linked-discord-wrapper {
        margin-top: 1rem;
        border: 1px solid #5f5f5f;
        border-radius: 1rem;
        padding: 1rem;
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
    }
}

.discord-information {
    margin-right: 1rem;
    flex: 1;
}

.discord-frame {
    iframe {
        height: 25rem;
    }
}

.logout {
    float: right;
    display: block;
    font-family: OpenSans-Regular;
    font-size: 25px;
    color: #ffffff;
    line-height: 1.2;
    text-align: right;
}

.package-name {
    width: 100%;
    display: block;
    font-family: OpenSans-Regular;
    font-size: 30px;
    color: #ffffff;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
}

.package-info {
    width: 100%;
    display: block;
    font-family: OpenSans-Regular;
    font-size: 25px;
    color: #ffffff;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    font-style: italic bold;
}

.full-button {
    background-color: #ffffff;
    color: rgb(22, 22, 22);
    font-weight: bold;
    border-radius: 5px;
    font-size: 1rem;
    padding: 0.5rem;
}

.large-button {
    font-size: 1.25rem;
    padding: 0.75rem;
}

.steambutton {
    display: block;
    background-color: #ffffff;
    width: 270px;
    height: 50px;
    line-height: 50px;
    margin: auto;
    color: rgb(22, 22, 22);
    position: absolute;
    cursor: pointer;
    overflow: hidden;
    border-radius: 5px;
}

.steambutton span {
    font-family: "OpenSans-Bold", sans-serif;
    color: rgb(22, 22, 22);
    letter-spacing: 0.1em;
    width: 75%;
    font-size: 14px;
    text-transform: uppercase;
    left: 0;
    -webkit-transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
    transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.steambutton span,
.steambutton .icon {
    display: block;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
}

.steambutton .icon .fa {
    font-size: 30px;
    line-height: 50px;
    -webkit-transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4), height 0.25s ease;
    transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4), height 0.25s ease;
}

.steambutton .icon {
    width: 25%;
    right: 0;
    -webkit-transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
    transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.steambutton span,
.steambutton .icon {
    display: block;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.steambutton span:after {
    content: "";
    background-color: #ffffff;
    width: 2px;
    height: 70%;
    position: absolute;
    top: 15%;
    right: -1px;
}

.steambutton.success span,
.steambutton:hover span {
    left: -72%;
    opacity: 0;
}

.steambutton.success .icon,
.steambutton:hover .icon {
    width: 100%;
}

.steambutton.success .icon .fa,
.steambutton:hover .icon .fa {
    font-size: 45px;
}

.vertical-line {
    display: inline-block;
    border-left: 1px solid #ffffff;
    margin: 0 10px;
    height: 125px;
}

hr {
    border-top-color: black;
    width: 70%;
    margin: 2rem auto !important;
}
hr::before {
    display: block;
    margin-left: -10%;
    margin-top: -1px;
    content: "";
    background: linear-gradient(to left, #ffffff, #ffffff);
    width: 10%;
    height: 1px;
}
hr::after {
    display: block;
    margin-left: 100%;
    margin-top: -1px;
    content: "";
    background: linear-gradient(to right, #ffffff, #ffffff);
    width: 10%;
    height: 1px;
}
