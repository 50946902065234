.discord-invite-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.discord-invite {
    display: flex;
    align-items: center;
    border: 1px solid #5f5f5f;
    border-radius: 0.75rem;
    padding: 0.75rem;
    margin: 0.5rem;

    .discord-invite-icon {
        height: 4rem;
        width: 4rem;
        margin-right: 0.5rem;
        border-radius: 50%;
    }

    .discord-invite-details {

        .discord-invite-servername {
            font-weight: 600;
            line-height: 2rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 13.4rem;
            display: block;
        }

        .discord-invite-members {
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            .discord-invite-member-group {
                display: flex;
                align-items: center;
                margin-right: 0.5rem;

                .discord-invite-members {
                    color: #b9bbbe;
                    font-size: 0.75rem;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }

        margin-right: 0.5rem;
    }

    .discord-invite-join {
        height: 2.5rem;
        border-radius: 0.25rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        color: #fff;
        background-color: #43b581;
        overflow: hidden;
    }
}

.discord-status-circle {
    display: inline-block;
    margin-right: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;

    &.status-online {
        background-color: #43b581;
    }

    &.status-offline {
        background-color: #747f8d;
    }
}
